import { useQuery } from '@apollo/client';
import { parseISO } from 'date-fns';
import { get, orderBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  AttachmentsDocument,
  type ButtonType,
  type ChatMessageType,
  ChatMessagesDocument,
  type ChatType,
} from 'frontend/api/generated';
import { mapToObject } from 'frontend/utils';
import randomUUID from 'frontend/utils/randomUUID';

import useWebhookRequests from './useWebhookRequests';
import { SYSTEM_MESSAGE_TYPE } from '../utils/constants';
import type { CompleteChatMessageType } from '../utils/types';

const CHAT_MESSAGES_LIMIT = Number(localStorage.getItem('CHAT_MESSAGES_LIMIT') || 100);

function getCreatedTimestamp({ created, createdAt }): number {
  return parseISO(created || createdAt).getTime();
}

type PartialChatMessage = Omit<
  ChatMessageType,
  'buttons' | 'attachments' | 'imageCarousel' | 'replyCandidates' | 'suggestions'
>;

function mapButton(
  chatMessage: PartialChatMessage,
  type: string,
  extra: Record<string, unknown>,
): (button: ButtonType) => {
  [buttonId: string]: {
    button: ButtonType;
    chatMessage: PartialChatMessage;
  } & {
    [key: string]: unknown;
  };
} {
  return (button) => ({
    [button.id]: { button: { ...button, type }, chatMessage, ...extra },
  });
}

function getUrl(message: PartialChatMessage, chat: ChatType): string | null {
  if (message.webHost && message.webPath) {
    return `https://${message.webHost}${message.webPath}`;
  }

  if (chat.webHost && chat.webPath) {
    return `https://${chat.webHost}${chat.webPath}`;
  }

  return null;
}

// TODO enable for future when we have reviews
export const IS_REVIEW = false;

export const BUTTON_CLICK_CONSTANT_FIELDS = {
  fromBot: false,
  exchangeType: null,
  fromWebhook: false,
  name: 'You',
  sender: 'USER',
};

// Find buttons in chat messages (including suggestions) and bundle them together with button clicks
export const handleButtonClicks = (buttonClicks, chatMessages) => {
  const buttonMapping = chatMessages.reduce(
    (mapping, { buttons, suggestions, imageCarousel, imageCarouselSize, ...rest }) => {
      const messageButtonsMap = mapToObject(mapButton(rest, 'buttons', { buttons }), buttons || []);
      const suggestionButtonsMap = mapToObject(
        ({ buttons: suggestionButtons }) =>
          mapToObject(mapButton(rest, 'suggestions', { suggestions }), suggestionButtons || []),
        suggestions || [],
      );

      const imageCarouselButtonsMap = mapToObject(
        ({ buttons: imageCarouselButtons }) =>
          mapToObject(
            mapButton(rest, 'imageCarousel', { imageCarousel, imageCarouselSize }),
            imageCarouselButtons || [],
          ),
        imageCarousel || [],
      );

      return {
        ...mapping,
        ...messageButtonsMap,
        ...suggestionButtonsMap,
        ...imageCarouselButtonsMap,
      };
    },
    {},
  );

  return (buttonClicks || []).reduce((clicks, { id, buttonId, time, __typename }) => {
    const { button, chatMessage, ...rest } = buttonMapping[buttonId] || {};
    if (!button) {
      return clicks;
    }

    const buttonClick = {
      ...chatMessage,
      id,
      button: {
        ...button,
        ...rest,
      },
      created: time,
      __typename,
      ...BUTTON_CLICK_CONSTANT_FIELDS,
    };

    return [...clicks, buttonClick];
  }, []);
};

const useChatMessages = (chat?: ChatType) => {
  const { botId, chatId } = useParams();

  const [loading, setLoading] = useState(true);

  const {
    data: chatMessages,
    fetchMore,
    loading: chatMessagesLoading,
  } = useQuery(ChatMessagesDocument, {
    variables: { botId: botId!, chatId: chatId!, limit: CHAT_MESSAGES_LIMIT },
    skip: !botId || !chatId,
    pollInterval: 20000, // refetch every 20 seconds
  });

  const { data: attachmentsData, loading: attachmentsLoading } = useQuery(AttachmentsDocument, {
    variables: { botId: botId as string, chatId: chatId as string },
    skip: !botId || !chatId,
  });

  const { webhooks, loading: webhookDataLoading } = useWebhookRequests(
    botId!,
    chatMessages?.chatMessages?.messages ?? [],
  );

  const groupedData: CompleteChatMessageType[][] = useMemo(() => {
    // So we know that the chat is loaded, but it returned nothing
    if (typeof chat === 'undefined') {
      setLoading(false);
    }

    if (chatMessagesLoading || webhookDataLoading || attachmentsLoading || !chat) {
      return [] as CompleteChatMessageType[][];
    }

    let previousUrl: string | null = null;

    const messages = [...(chatMessages?.chatMessages?.messages || [])];
    if (messages?.[0]) {
      messages[0] = {
        ...messages[0],
        messageReferences: [
          ...(messages[0].messageReferences || []),
          {
            id: 'chat-start',
            url: 'https://www.kindly.ai',
            offset: 23,
          },
          {
            id: 'test',
            url: 'https://www.kindly.ai/aaa',
            offset: 23,
          },
          {
            id: 'testa',
            url: 'https://www.kindly.ai/aaaaaa',
            offset: 38,
          },
        ],
      };
    }
    const allData = [
      ...(messages ?? []),
      ...handleButtonClicks(chat.buttonClicks, messages ?? []),
      ...(webhooks ?? []),
    ];

    if (chat.feedbacks?.length) {
      const [feedback] = chat.feedbacks;
      allData.push(
        {
          feedback,
          __typename: SYSTEM_MESSAGE_TYPE.FEEDBACK,
          created: feedback?.createdAt,
          id: 'feedback-log-item',
        },
        feedback,
      );
    }

    if (chat?.userLeft) {
      allData.push({
        __typename: SYSTEM_MESSAGE_TYPE.USER_LEFT,
        created: chat?.userLeft,
        id: 'user-left-log-item',
      });
    }

    let dataOrdered = orderBy(allData, getCreatedTimestamp, 'asc');

    // So we keep the same structure
    // [[chatMessage], [chatMessage]]
    dataOrdered = dataOrdered.reduce((acc, currValue) => {
      const currValueCopy = { ...currValue, ...(currValue.attachments && { attachments: currValue.attachments }) };

      // Handle legacy + new attachments
      if (currValueCopy.attachmentIds?.length) {
        const foundAttachments = attachmentsData?.attachments.filter(({ id }) =>
          currValueCopy.attachmentIds.includes(id),
        );
        currValueCopy.attachments = [...currValueCopy.attachments, ...(foundAttachments || [])];
      }

      const url = getUrl(currValueCopy, chat);

      if (IS_REVIEW) {
        const foundIndex = acc.findIndex(([{ id }]) => id === currValueCopy.replyToId);

        if (foundIndex > -1) {
          acc[foundIndex].push(currValueCopy);
          return acc;
        }
      }

      const data: CompleteChatMessageType[][] = [
        ...acc,
        ...(url && previousUrl !== url
          ? [
              [
                {
                  message: url,
                  created: currValueCopy.created,
                  __typename: SYSTEM_MESSAGE_TYPE.URL,
                  id: randomUUID(),
                },
              ],
            ]
          : []),
        [currValueCopy],
      ];

      if (url) {
        previousUrl = url;
      }
      setLoading(false);

      return data;
    }, [] as CompleteChatMessageType[][]);

    return dataOrdered;
  }, [chat, chatMessages, webhooks, chatMessagesLoading, webhookDataLoading, attachmentsLoading, attachmentsData]);

  const loadMore = useCallback(() => {
    const messages = orderBy(get(chatMessages, 'chatMessages.messages', []), ['created'], ['asc']);
    const before = get(messages, '[0].created', null);

    fetchMore({
      variables: { before },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          chatMessages: {
            ...prev.chatMessages,
            messages: [...(prev.chatMessages?.messages || []), ...(fetchMoreResult.chatMessages?.messages || [])],
          },
        };
      },
    });
  }, [chatMessages, fetchMore]);

  return {
    loading,
    data: groupedData as CompleteChatMessageType[][],
    hasMore: (chatMessages?.chatMessages?.remaining || 0) > 0,
    loadMore,
  };
};

export default useChatMessages;
